import React from 'react';
import 'bootstrap';
import Header from '../components/header';
import Footer from '../components/footer';

export default () => {
  return (
    <>
      <Header />
        <div className='container col-xs-12'>
          <div className='row mt-5 mb-5'>
            <div className="container">
              <div >
                <p>Something went wrong!</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  );
}